<template>
  <div>
    <div class="show">
      <div class="showTop">
        <div class="topleft">
          <img src="../../src/assets/whitelogo.png" alt="" />
          <p>欢迎登录蹄司令智能管理系统</p>
        </div>
        <div class="topright">
          <p>欢迎您，管理员</p>
          <i class="el-icon-switch-button" @click="outdow"></i>
        </div>
      </div>
      <div class="main">
        <el-row class="main" type="flex">
          <el-col :span="3">
            <el-menu
              :default-active="
                $route.path === '/userdetail' ? '/alarmuse' : $route.path
              "
              class="el-menu-vertical-demo"
              background-color="#e5e5e5"
              text-color="rgba(86, 119, 34, 100)"
              active-text-color="white"
              style="padding: 10px 0px"
              router
            >
              <el-menu-item index="/equipuse">
                <i class="el-icon-takeaway-box"></i>
                <span slot="title">设备管理</span>
              </el-menu-item>
              <el-menu-item index="/alarmuse">
                <i class="el-icon-s-opportunity"></i>
                <span slot="title">报警管理</span>
              </el-menu-item>
              <el-menu-item index="/systemuse">
                <i class="el-icon-menu"></i>
                <span slot="title">系统管理</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="21" style="min-height: calc(100vh - 76px)">
            <router-view ></router-view>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { outdown } from "../api/login/index";
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    outdow() {
      outdown().then((res) => {
        console.log(res);
        this.$store.commit("saveToken", res.token);
        console.log(this.$store.state.token);
        this.$router.push("/");
      });
    },
    }
};
</script>


<style lang='less' scopped>
* {
  box-sizing: border-box;
}
.showTop {
  width: 100%;
  height: 60px;
  background: rgba(86, 119, 34, 100);

  color: white;
  display: flex;
  justify-content: space-between;
  .topleft {
    width: 700px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    p {
      font-weight: 400;
      margin-left: 100px;
      font-size: 20px;
    }
    img {
      width: 160px;
      height: 50px;
      margin-left: 10px;
    }
  }
  .topright {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
    > i {
      margin-left: 10px;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.main {
  min-height: calc(100vh - 76px);
  background-color: #f2f2f2;
  /deep/.el-menu {
    height: 100%;
  }
  /deep/.el-menu-item.is-active {
    background-color: rgba(86, 119, 34, 100) !important;
  }
  /deep/.el-menu-item {
    font-size: 20px;
    height: 60px;
    // font-size: 14px;
    text-align: center;
    font-family: Roboto;
  }
}
</style>
